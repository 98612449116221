<template>
  <div
    v-if="!userStore.billerJoinEpochDismissed"
    class="z-40 fixed bottom-2 right-3 flex flex-col items-center bg-black/90 rounded-md p-4"
  >
    <div class="absolute right-2 top-2 w-[20px] h-[20px]" @click="handleClose">
      <font-awesome-icon icon="fa-regular fa-xmark" class="text-xl" />
    </div>
    <p class="text-lg">Join this site</p>
    <p class="text-xl leading-tight">
      Securely with
      <span class="italic font-bold">PayPal</span>
    </p>
    <div class="flex flex-row items-center gap-1 mt-2">
      <img
        width="70"
        height="37"
        src="/images/epoch-join-visa.png"
        alt="paypal"
      />
      <img
        width="70"
        height="37"
        src="/images/epoch-join-discover.png"
        alt="paypal"
      />
      <img
        width="70"
        height="37"
        src="/images/epoch-join-mastercard.png"
        alt="paypal"
      />
      <img
        width="70"
        height="37"
        src="/images/epoch-join-paypal.png"
        alt="paypal"
      />
    </div>
  </div>
</template>

<script setup>
  const userStore = useUserStore()
  const handleClose = () => {
    userStore.dismissBillerJoinEpoch()
  }
</script>
