<template>
  <ClientOnly>
    <AgeConfirmationPopup v-if="!userStore.ageConfirmed" />
  </ClientOnly>

  <div ref="content" class="hidden">
    <ClientOnly>
      <TourClassicNavigation />
    </ClientOnly>

    <div class="w-full flex flex-col items-center">
      <slot />
    </div>

    <Footer />
  </div>

  <ClientOnly>
    <BillerEpochJoinBox />
    <SaleSpecial />
  </ClientOnly>
</template>

<script setup lang="ts">
  const userStore = useUserStore()

  useHead({
    bodyAttrs: {
      class: "tour-classic",
    },
  })

  const content: Ref<HTMLElement | null> = ref(null)

  const showContent = () => {
    content.value?.classList.remove("hidden")
  }

  watch(
    () => userStore.ageConfirmed,
    (ageConfirmed) => {
      if (ageConfirmed) {
        showContent()
      }
    },
  )

  onMounted(() => {
    if (userStore.ageConfirmed) {
      showContent()
    }
  })
</script>
