<template>
  <ClientOnly>
    <div
      v-if="!userStore.ageConfirmed"
      class="fixed inset-0 z-[1000] flex items-center justify-center h-screen w-screen bg-black"
    >
      <div class="relative max-w-[350px] flex flex-col items-start gap-4 p-4">
        <div class="flex flex-col gap-4">
          <p
            class="font-extrabold text-[#D90000] text-5xl tracking-tight leading-6"
          >
            18+
          </p>
          <p class="text-white">
            This site contains sexually explicit material.
          </p>
          <p class="text-white/75">
            This website contains content intended for adults only. If you are
            under 18 years of age, or below the legal age of majority in your
            location, you are not authorized to access this website or its
            content. By entering this website, you confirm that you are at least
            18 years old or have reached the age of majority in your location
            and agree to adhere to the
            <a href="/terms" target="_blank" class="text-blue-500 underline">
              Terms &amp; Conditions
            </a>
            .
          </p>
        </div>

        <p class="text-white/50">
          By clicking the "Continue" button and entering this website, you
          acknowledge and agree to the statements above and certify, under
          penalty of perjury, that you are an adult.
          <a href="/terms" target="_blank" class="text-blue-500 underline">
            Terms &amp; Conditions
          </a>
          and
          <a href="/privacy" target="_blank" class="text-blue-500 underline">
            Privacy Policy
          </a>
          .
        </p>
        <div class="flex flex-row gap-2 leading-[26px] mb-4 -ml-1">
          <button
            class="px-8 py-4 rounded-full bg-[#0086D9] text-white font-extrabold sm:text-lg leading-none text-center"
            @click="handleAgree"
          >
            Continue
          </button>
          <button
            class="px-8 py-4 rounded-full bg-gray-800 text-white font-extrabold sm:text-lg leading-none text-center"
            @click="handleLeave"
          >
            Leave
          </button>
        </div>
      </div>
    </div>
  </ClientOnly>
</template>

<script setup>
  const userStore = useUserStore()
  const handleAgree = () => {
    userStore.confirmAge()
  }
  const handleLeave = () => {
    window.location.href = "https://google.com"
  }
</script>
