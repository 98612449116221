<template>
  <div
    v-if="!userStore.isSaleSpecialDismissed && saleSpecial"
    class="fixed bottom-0 left-0 right-0 text-center bg-black bg-black/90 z-50 border-t border-[#333]"
  >
    <div class="absolute right-2 top-2 w-[20px] h-[20px]" @click="handleClose">
      <font-awesome-icon icon="fa-regular fa-xmark" class="text-xl" />
    </div>
    <div
      class="w-full max-w-7xl mx-auto flex flex-col sm:flex-row items-center gap-2"
    >
      <div
        ref="tick"
        class="tick w-full sm:w-1/2 mx-auto p-0"
        data-credits="false"
        data-did-init="handleSaleSpecialTickInit"
      >
        <div
          data-layout="horizontal fit"
          data-repeat="true"
          data-transform="preset(d, h, m, s) -&gt; delay"
        >
          <div class="tick-group">
            <span class="tick-label" data-key="label" data-view="text"></span>
            <div
              data-key="value"
              data-repeat="true"
              data-transform="pad(00) -&gt; split -&gt; delay"
            >
              <span data-view="flip"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full sm:w-1/2 mx-auto flex flex-col items-center">
        <NuxtImg
          class="w-full cursor-pointer"
          :src="saleSpecial.imageUrl"
          loading="lazy"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
  import Tick from "@pqina/flip"
  import "@pqina/flip/dist/flip.min.css"

  const userStore = useUserStore()
  const tick = ref(null)

  const { data: saleSpecial } = await useApi(`/api/sale_specials`)

  const handleClose = () => {
    userStore.dismissSaleSpecial()
  }

  onMounted(() => {
    window.handleSaleSpecialTickInit = (t) => {
      Tick.count.schedule("every day at 24:00").onupdate = (v) => {
        t.value = v
      }
    }
    setTimeout(() => {
      Tick.DOM.parse(document.body)
    }, 500)
  })

  onUnmounted(() => {
    Tick.DOM.destroy(tick.value)
  })
</script>

<style scoped>
  .tick {
    font-size: 0.6rem;
    white-space: nowrap;
    font-family: arial, sans-serif;
  }

  .tick-flip,
  .tick-text-inline {
    font-size: 2.5em;
  }

  .tick-label {
    margin-bottom: 0.5em;
    font-size: 0.5em;
  }

  .tick-char {
    width: 1.5em;
  }

  .tick-text-inline {
    display: inline-block;
    text-align: center;
    min-width: 1em;
  }

  .tick-text-inline + .tick-text-inline {
    margin-left: -0.325em;
  }

  .tick-group {
    margin: 0 0.5em;
    text-align: center;
  }
</style>
